export interface News {
  title: string
  content: string
  link: string
  date: Date
}

export const newsList: News[] = [
  {
    title: "Championnats Régionaux salle 2024",
    content: "La période sélective est terminée. La liste de tous les sélectionnés est publiée.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2024, 0, 28),
  },
  {
    title: "Assemblées Générales 2024",
    content: "Une AG extraordinaire se tiendra à Tomblaine le 17 février à 10h00 et concernera la modification des statuts du CRTA Grand Est. Elle sera immédiatement suivie de l'AG Ordinaire.",
    link: "/comite-regional/pv-ag/",
    date: new Date(2024, 0, 6),
  },
  {
    title: "Championnats Régionaux salle 2024",
    content: "Les pré-inscriptions sont ouvertes.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2023, 11, 15),
  },
  {
    title: "Candidature Championnats Régionaux 2025",
    content: "Responsables de clubs, vous avez la possibilité de proposer votre candidature à l'organisation d'un championnat régional pour la saison prochaine.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2023, 11, 16),
  },
  {
    title: "Championnat Régional Beursault 2023",
    content: "Les résultats du Championnat Régional Beursault 2023 sont publiés.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2023, 8, 1),
  },
  {
    title: "Championnats Régionaux salle 2023",
    content: "La période sélective est terminée. La liste de tous les sélectionnés est publiée.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2023, 0, 31),
  },
  {
    title: "Nouvelle formation",
    content: "Introduction au para-tir à l'arc.",
    link: "/formations/formation-intro-para/",
    date: new Date(2022, 9, 30),
  },
  {
    title: "DRE 2022",
    content: "Le classement final de la DRE 2022 est disponible.",
    link: "/vie-sportive/dre/",
    date: new Date(2022, 5, 13),
  },
  {
    title: "Formations 2023",
    content: "Le catalogue des formations 2023 est disponible.",
    link: "/formations/",
    date: new Date(2022, 5, 1),
  },
  {
    title: "Weekend Horizon 2022",
    content: "Les inscriptions pour le weekend Horizon sont ouvertes.",
    link: "/formations/weekend-horizon-2022/",
    date: new Date(2022, 4, 30),
  },
  {
    title: "Nouvel organigramme",
    content: "Le nouvel organigramme du Comité Régional est disponible.",
    link: "/comite-regional/equipe/",
    date: new Date(2022, 2, 13),
  },
  {
    title: "Stages de détection Avril",
    content: "La billeterie pour les stages de détection du mois d'Avril 2022 est ouverte.",
    link: "/formations/detection-2021-2022/",
    date: new Date(2022, 2, 4),
  },
  {
    title: "Calendrier extérieur 2022",
    content: "Le calendrier pour les concours en extérieur, dont tradition, a été mis à jour.",
    link: "/vie-sportive/calendrier/",
    date: new Date(2022, 2, 3),
  },
  {
    title: "Classement à jour",
    content: "Le classement pour les Championnats Régionaux en Salle ont été mis à jour.",
    link: "/vie-sportive/classement/",
    date: new Date(2022, 1, 28),
  },
  {
    title: "Archers sélectionnés",
    content: "Les archers adultes sélectionnés pour le Championnat Régional en Salle sont disponibles.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2022, 1, 9),
  },
  {
    title: "Classement à jour",
    content: "Le classement pour les Championnats Régionaux en Salle ont été mis à jour.",
    link: "/vie-sportive/classement/",
    date: new Date(2022, 1, 7),
  },
  {
    title: "Calendrier extérieur 2022",
    content: "Le calendrier pour les concours en extérieur a été mis à jour.",
    link: "/vie-sportive/calendrier/",
    date: new Date(2022, 0, 18),
  },
  {
    title: "Championnats Régionaux en Salle",
    content: "Les mandats et classement pour les Championnats Régionaux en Salle ont été mis en ligne.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2022, 0, 12),
  },
  {
    title: "Championnats Régionaux en Salle",
    content: "Les Championnats Régionaux en Salle ont été mis en ligne.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2022, 0, 3),
  },
  {
    title: "Arbitrage",
    content: "Le calendrier d'arbitrage a été mis à jour.",
    link: "/vie-sportive/calendrier/",
    date: new Date(2022, 0, 3),
  },
  {
    title: "Résultats régionaux disponibles",
    content: "Les résultats du championnat régional Beursault 2021 sont disponibles.",
    link: "/vie-sportive/championnats-regionaux/",
    date: new Date(2021, 8, 5),
  },
  {
    title: "Guide des formations 2021-2022",
    content: "Le guide des formations saison 2021-2022 a été mis en ligne.",
    link: "/formations/",
    date: new Date(2021, 6, 13),
  },
]
